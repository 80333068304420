import { ReactNode, useEffect, useContext, useMemo, useState } from 'react';
import { AuthType } from 'app/modules/account/account.context.d';
import AccountStore from 'app/modules/account/account.context';
import SettingsStore from 'app/modules/settings/settings.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import Skeleton from 'stories/layout/skeleton/skeleton';
import SlickTable from 'app/components/slick-table/slick-table';
import { TrackingSheetContainer, TrackingSheetSkeletonWrapper } from './tracking-sheet.style';
import { generateColumns, handleDrag, refreshETE } from './tracking-sheet.controller';
import { TrackingPointCascadeType } from 'app/modules/inspection/inspection.interfaces';
import ResizableWrapper from '../resizable-wrapper/resizable-wrapper';
import NotificationStore from 'app/modules/notification/notification.context';
import GTDataView from 'app/components/slick-table/slick-table.dataview';
import { Column } from 'slickgrid';
import { useHistory } from 'react-router-dom';

interface Props {
  container: React.RefObject<HTMLDivElement>;
  setBounds?: (data: google.maps.LatLng[]) => void;
}

/**
 * Tracking sheet component
 */
const TrackingSheet = (props: Props) => {
  const history = useHistory(); 
  const accountContext = useContext(AccountStore);
  const inspectionContext = useContext(InspectionStore);
  const notificationContext = useContext(NotificationStore);
  const settingsContext = useContext(SettingsStore);
  const [dataView, setDataView] = useState<GTDataView<TrackingPointCascadeType>>();
  const [headerDragging, setHeaderDragging] = useState<string>();
  const auth = accountContext.state.auth as AuthType;
  const lindex = inspectionContext.run?.trackingpoint_set?.length
    ? inspectionContext.run?.trackingpoint_set?.length - 1 : 0;

  const metadata = useMemo(() => {
    return {
      auth,
      permission_type: inspectionContext.state.permission_type,
      launched: inspectionContext.run?.launched || false,
      predicted_launch_speed: inspectionContext.run?.predicted_launch_speed,
      units: {
        speed: settingsContext.state.speedUnit,
        distance: settingsContext.state.distanceUnit,
        timezone: settingsContext.state.timezone,
      },
    };
  }, [
    auth,
    inspectionContext.run?.launched,
    inspectionContext.state.permission_type,
    settingsContext.state.speedUnit,
    settingsContext.state.distanceUnit,
    settingsContext.state.timezone,
  ]);

  // create columns definitions to run sheet
  const columns = useMemo(() => {
    return generateColumns(
      inspectionContext,
      settingsContext.state,
      history,
      inspectionContext.state.permission_type !== 'editor',
      auth,
      (bounds: google.maps.LatLng[]) => {
        if(props.setBounds) props.setBounds(bounds);
      },
      ({ title, text, type }: {title: string, text: string, type: string}) => {
        notificationContext.dispatch({
          type: 'SET_TOAST',
          data: { title, text, type }
        })
      },
    );
  }, [inspectionContext.state.permission_type]);

  useEffect(() => {
    if (inspectionContext.state.grid) {
      const grid = inspectionContext.state.grid;

      const gridEl = grid.getContainerNode();
      const columnsEl = gridEl.querySelectorAll('.slick-header-column');

      const attachDraggable = () => {
        for (const el of columnsEl) {
          el.setAttribute('draggable', 'true');
          el.removeEventListener('dragstart', handleDrag);  
          el.addEventListener('dragstart', handleDrag);
        }
      };

      const handleDrag = (e) => {
        const target = e.target as HTMLElement;
        const field = target.dataset.id as string;
        setHeaderDragging(field);
      };

      attachDraggable();
    }
  }, [inspectionContext.state.grid, headerDragging])

  useEffect(() => {
    if (!inspectionContext.state.grid) return;
    
    const pairedColumns = ['triggers', 'input_type'];
    const grid = inspectionContext.state.grid;

    grid.onColumnsReordered.subscribe((e, args) => {
      const currentOrder = [...args.impactedColumns];
      
      if (!headerDragging || !pairedColumns.includes(headerDragging)) return;

      const pairedIndices = pairedColumns.map((id) => currentOrder.findIndex((col) => col.id === id));
      const [first, second] = pairedIndices;
      const [firstColumn, secondColumn] = pairedColumns.map((id) => currentOrder.find((col) => col.id === id)) as Column[];

      if (firstColumn.id === headerDragging) {
        // remove second column from current order
        currentOrder.splice(second, 1);

        // insert second column after first column
        const targetIndex = second < first ? first : first + 1;
        currentOrder.splice(targetIndex, 0, secondColumn);
      }

      if (secondColumn.id === headerDragging) {
        // remove first column from current order
        currentOrder.splice(first, 1);

        // insert first column before second column
        const targetIndex = first > second ? second : second - 1;
        currentOrder.splice(targetIndex, 0, firstColumn);
      }

      grid.setColumns(currentOrder);
      setHeaderDragging(undefined);
    });

    return () => {
      grid.onColumnsReordered.unsubscribe();
    };
  }, [headerDragging]);

  // refresh the ete column using tick
  useEffect(() => {
    if (inspectionContext.state.grid && dataView) refreshETE(inspectionContext.state.grid, dataView);
  }, [inspectionContext.state.tick]);

  useEffect(() => {
    handleDrag(0, props.container.current, inspectionContext.state.grid);
  }, [inspectionContext.run?.trackingpoint_set?.[lindex]?._tstamp, inspectionContext.state.grid]);

  // highlight on grid the selected tracking point
  useEffect(() => {
    if (inspectionContext.state.grid && inspectionContext.state.selected_point) {
      const row = inspectionContext.state.selected_point.index;
      inspectionContext.state.grid.setSelectedRows([row]);
    }
  }, [inspectionContext.state.selected_point]);
  
  // create skeletons to loading
  const skeletons: ReactNode[] = [];
  for (let c = 0; c <= 600; c += 1) {
    skeletons.push(<Skeleton key={c} loading height="20px" />);
  }

  // check if can be render the run
  if (!inspectionContext.state.permission_type || !inspectionContext.run || !inspectionContext.run?.trackingpoint_set?.length) {
    // render load state
    return (
    <ResizableWrapper
      onDragEnd={() => ''}
      onDrag={({ deltaY }) => {
        handleDrag(deltaY, props.container.current, inspectionContext.state.grid)
      }}
      disabled={false}
      grid={inspectionContext.state.grid}
    >
      <TrackingSheetContainer>
        <TrackingSheetSkeletonWrapper>{skeletons}</TrackingSheetSkeletonWrapper>
      </TrackingSheetContainer>
    </ResizableWrapper>
    )
  }
  
  return (
    <ResizableWrapper
      onDragEnd={() => ''}
      onDrag={({ deltaY }) => {
        handleDrag(deltaY, props.container.current, inspectionContext.state.grid)
      }}
      disabled={false}
      grid={inspectionContext.state.grid}
    >
      <TrackingSheetContainer data-testid="tracking-sheet">
        <SlickTable<TrackingPointCascadeType>
          permissionType={inspectionContext.state.permission_type}
          columns={columns}
          data={inspectionContext.run.trackingpoint_set || []}
          grid={inspectionContext.state.grid}
          options={{
            editable: inspectionContext.state.permission_type === 'editor',
            frozenColumn: 3,
          }}
          onLoad={(grid, data) => {
            setDataView(data)
            inspectionContext.dispatch({ type: 'SET_SLICKGRID', data: grid });
          }}
          metadata={metadata}
          noColumnPicker
        />
      </TrackingSheetContainer>
      {inspectionContext.run.trackingpoint_set.map((point) => {
        return (
          <div key={`info-of-${point.index}`} >
            <label className="hidden" data-testid={`passage-at-${point.index}`}>{point.passage?.tstamp}</label>
            <label className="hidden" data-testid={`eta-at-${point.index}`}>{point.eta}</label>
          </div>
        )})}
    </ResizableWrapper>
  );
};

export default TrackingSheet;
