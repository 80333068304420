import { formatTimezoneDate } from "@/lib/formatter";
import { TriggerType } from "app/modules/detection/detection.interfaces";
import { MagneticData } from "app/modules/inspection/run/components/charts/components/magnetic-chart/magnetic-chart";
import { SettingsContext } from "app/modules/settings/settings.context.d";
import { Timezone } from "app/modules/settings/settings.context.d";
import axios from "axios";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";

const API_URL = import.meta.env.VITE_API_URL;
const LOCAL_URL = import.meta.env.VITE_LOCAL_URL;

const m = 60;
const h = m * 60;

const THRESHOLDS = [
  1,
  5,
  10,
  15,
  30,
  60,
  2 * m,
  5 * m,
  10 * m,
  15 * m,
  20 * m,
  30 * m,
  h,
  2 * h,
  3 * h,
  6 * h,
  12 * h,
];

const TICKS = 4;

export const normalizeTstamp = (tstamp: string | undefined): Date | undefined => {
  if (!tstamp) return new Date('invalid');
  if(/\+/g.test(tstamp)) {
    return new Date(`${tstamp.split('+')[0]}Z`);
  }
  return new Date(tstamp)
}

export const getTriggerTstamp = (trigger: TriggerType | undefined): Date | undefined => {
  return normalizeTstamp(trigger?.tstamp);
}

export const formatTimeTick = (
  tick: Date,
  i: number,
  ticks: Date[],
  timezone: SettingsContext['timezone'],
) => {
  if (!tick) return;
  if (!tick.getTime) tick = new Date(tick);

  let peer = i > 0 ? ticks[i - 1] : ticks[i + 1];
  if (!peer) {
    return formatTimezoneDate({
      date: tick,
      timezone: timezone?.id || '',
      format: 'HH:mm:ss',
    });
  }

  if (!peer.getTime) peer = new Date(peer);

  const delta = Math.abs(peer.getTime() - tick.getTime()) / 1000;

  if (delta < 90) {
    return formatTimezoneDate({
      date: tick,
      timezone: timezone?.id || '',
      format: 'HH:mm:ss',
    });
  }

  return formatTimezoneDate({
    date: tick,
    timezone: timezone?.id || '',
    format: 'HH:mm',
  });
};

export const getZoomTicks = (range: [Date, Date] | null) => {
  if (!range) return [];
  if (!range[0].getTime) return [];

  const start = range[0].getTime();
  const end = range[1].getTime();
  const delta = (end - start) / 1000;
  let step;

  for (let i = 0; step === undefined && i < THRESHOLDS.length; i++) {
    const threshold = THRESHOLDS[i];
    if (delta < threshold * (TICKS + 1)) {
      step = THRESHOLDS[i];
    }
  }
  if (!step) step = THRESHOLDS[THRESHOLDS.length - 1];

  const periods = Math.floor(start / (1000 * step));
  let tstamp = new Date(periods * step * 1000);
  const ticks: any = [];
  while (tstamp.getTime() < end) {
    const tick = new Date(
      tstamp.getFullYear(),
      tstamp.getMonth(),
      tstamp.getDate(),
      tstamp.getHours(),
      tstamp.getMinutes(),
      tstamp.getSeconds()
    );
    ticks.push(tick);
    tstamp = new Date(tick.getTime() + step * 1000);
  }

  return ticks;
};

export const getMediaUrl = async (trigger, media, run_id, token) => {
  const tMedia = trigger?.medias ? trigger.medias[media] : '';
  const resp = await axios.get(`${API_URL}/medias/trigger/${run_id}/${tMedia}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );
  const url = resp.data;

  const isNotLocal = /^https?:/.test(url);
  const localUrl = LOCAL_URL || 'http://localhost:8000';
  
  if (!isNotLocal) {
    return `${localUrl}${url}`;
  }

  return `${isNotLocal ? '' : localUrl}${url}`;
}

export const getXYData = async (
  trigger: TriggerType,
  timezone: Timezone['id'],
  setMagneticData: (data: {x: MagneticData[], y: MagneticData[]}) => void,
  setRangeY: (data: [number, number]) => void,
  rangeY: [number, number] | undefined,
  setRangeX: (data: [Date, Date]) => void,
  setLoading: (data: boolean) => void,
  type: 'dc' | 'ac',
  run_id: string | number,
  token: string,
) => {
  setLoading(true);
  const xUrl = await getMediaUrl(trigger, `${type}x`, run_id, token);
  let xArr;
  try {
    const xResp = await axios.get(xUrl, { responseType: 'arraybuffer' });
    xArr = decodeMagneticData(xResp.data);
  } catch (err) {
    setMagneticData({ x: [], y: [] });
    setLoading(false);
    throw new Error('Error to get ACX')
  }
  const yUrl = await getMediaUrl(trigger, `${type}y`, run_id, token);
  let yArr;
  try {
    const yResp = await axios.get(yUrl, { responseType: 'arraybuffer' });
    yArr = decodeMagneticData(yResp.data);
  } catch {
    setLoading(false);
    setMagneticData({ x: [], y: [] });
    throw new Error('Error to get ACY')
  }

  const data = {
    x: xArr ? getTimeOnMagneticData(xArr.floatArray, trigger, timezone) : [],
    y: yArr ? getTimeOnMagneticData(yArr.floatArray, trigger, timezone) : [],
  };

  setMagneticData(data);

  const rangeX = [data.x[0].x, data.x[data.x.length - 1].x] as [Date, Date];
  setRangeX(rangeX);

  const min = Math.min(xArr?.min, yArr?.min)
  const max = Math.max(xArr?.max, yArr?.max);
  if (!rangeY || (rangeY[0] !== min || rangeY[1] !== max)) setRangeY([min, max])
  setTimeout(() => {
    setLoading(false);
  }, 200);
}

export const getGeophoneData = async (
  trigger: TriggerType,
  timezone: Timezone['id'],
  setChartData: (data: MagneticData[]) => void,
  setWaveData: Dispatch<SetStateAction<Float32Array | undefined>>,
  setRangeY: (data: [number, number]) => void,
  rangeY: [number, number] | undefined,
  setRangeX: (data: [Date, Date]) => void,
  setLoading: (data: boolean) => void,
  runId: string,
  token: string,
) => {
  setLoading(true);
  const url = await getMediaUrl(trigger, 'gphone', runId, token);
  let arr;
  try {
    const resp = await axios.get(url, { responseType: 'arraybuffer' });
    arr = resp.data;
  } catch (err) {
    const emptyArray = new Float32Array(0);
    setWaveData(emptyArray);
    setLoading(false);
    throw new Error('Error to get Geophone');
  }

  const byteArray = new Uint8Array(arr);
  const waveData = new Float32Array(byteArray.length);
  const length = Math.floor(byteArray.length / 400);
  const chartData = new Array<MagneticData>(length);
  // Geophone data starts 15s after magnetic data
  const date = moment.tz(trigger.medias._start, timezone || 'UTC');
  const start = date.toDate().getTime() + 15000;
  let min = byteArray[0];
  let max = min;
  let total = 0;

  for (let i = 0; i < length; i++) {
    total += byteArray[i];
  }
  const avg = total / length;
  max -= avg;
  min -= avg;
  // Let's make 0.1s steps to match other charts
  for (let i = 0; i < length; i++) {
    let acc = 0;
    for (let j = 0; j < 400; j++) {
      const k = i * 400 + j;
      waveData[k] = byteArray[k] - avg;
      acc += byteArray[k];
    }
    const y = acc / 400 - avg;
    min = Math.min(min, y);
    max = Math.max(max, y);

    chartData[i] = {
      'y': y,
      'x': moment.tz(start + i * 100, timezone || 'UTC').toDate(),
    };
  }

  setWaveData(waveData);
  setChartData(chartData);

  if (!rangeY || (rangeY[0] !== min || rangeY[1] !== max)) setRangeY([min, max])
  setTimeout(() => {
    setLoading(false);
  }, 200);
}


const getTimeOnMagneticData = (data, trigger, timezone) => {
  const date = moment.tz(trigger.medias._start, timezone || 'UTC');
  const tstamp = date.toDate().getTime();
  return data.map((value: number, i: number) => {
    const currentTZTime = moment.tz(tstamp + i * 100, timezone || 'UTC');
    const currentTime = currentTZTime.toDate();

    return { x: currentTime, y: value };
  });
}

export const  decodeMagneticData = (buffer: ArrayBuffer) => {
    const floatArray: number[] = [];
    const dataView: DataView = new DataView(buffer);
    let min = 0;
    let max = 0;

    // Iterate over each 4-byte block
    for (let i = 0; i < buffer.byteLength; i += 4) {
      // Read a float; second parameter is the byte offset
      // Assuming little endian; set to true if the data is in big endian
      const float: number = dataView.getFloat32(i, true);
      if (float < min) {
        min = float;
      } else if (float > max) {
        max = float;
      }
      floatArray.push(float);
    }

    return {
      floatArray,
      max,
      min,
    };
}
