import { useContext, useEffect, useMemo } from 'react';
import { formatTimezoneDate } from '@/lib/formatter';
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'qs';
import SettingsStore from 'app/modules/settings/settings.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';
import { TriggerType } from 'app/modules/detection/detection.interfaces';
import SatellitePassages from '../../../../components/satellite-passages/satellite-passages';
import Typograph from 'stories/type/typograph/typograph';
import Overlay from 'stories/layout/overlay/overlay';
import Charts from 'app/modules/inspection/run/components/charts/charts';
import { getTriggerTstamp } from 'app/modules/inspection/run/devices/desktop/components/qube-triggers-overlay/qube-triggers-overlay.controller';
import {
  AGMTitle,
  QubeTriggersOverlayContent,
  QubeTriggersOverlayContentWrapper,
  QubeTriggersOverlayPresetContainer,
  QubeTriggersOverlaySidebar,
} from './qube-triggers-overlay.style';

export const modal = 'trigger-detail'

/**
   * 
   * @param triggerid 
   * @param tpointid 
   * @returns 
   */
export const getURL = (tpointid: number, triggerid?: number) => {
  const url = new URL(window.location.href);
  url.searchParams.set('modal', modal);
  url.searchParams.set('tpointid', tpointid.toString());
  if (triggerid) url.searchParams.set('triggerid', triggerid.toString());
  return url.pathname + url.search;
};

/**
 * this component should render floating the run
 * in the right side, showing all informations triggers
 * captured by qube
 */
const QubeTriggersOverlay = () => {
  const history = useHistory();
  const location = useLocation();

  const settingsContext = useContext(SettingsStore);
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;

  const triggerid = useMemo(() => {
    const query = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    return query.triggerid;
  }, [location]);

  const tpointid = useMemo(() => {
    const query = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    return query.tpointid;
  }, [location]);

  const agm = useMemo(() => {
    return inspectionContext.run?.trackingpoint_set?.find(
      ({ id }) => id === Number(tpointid)
    );
  }, [location, inspectionContext.run?.trackingpoint_set]);

  const triggers = useMemo(() => {
    if (!agm) return;
    return inspectionContext.run?.trackingpoint_set?.[agm?.index]?.trigger_set;
  }, [agm, inspectionContext.run?.trackingpoint_set?.[agm?.index]?.trigger_set]);

  const trigger = useMemo(() => {
    if (!triggers) return;
    return triggers.find(({ id }) => id === Number(triggerid));
  }, [agm, triggerid]);

  const open = useMemo(() => {
    const query = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    return query.modal === modal;
  }, [location]);

  /**
   * 
   */
  const handleClose = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('modal');
    url.searchParams.delete('triggerid');
    url.searchParams.delete('tpointid');

    QueryString.stringify(url.searchParams, { addQueryPrefix: true });
    history.push(url.pathname + url.search);
  };
  
  useEffect(() => {
    if (!agm) return;
    
    const approved = triggers?.find(
      ({ processed, passage }) => processed && passage
    );

    const rejected = triggers?.filter(
      ({ processed, passage }) => processed && !passage
    );

    const toBeApproved = triggers?.filter(
      ({ processed }) => !processed
    );

    const preSelectedTrigger = approved || toBeApproved?.[0] || rejected?.[0];

    if (!triggerid && preSelectedTrigger) {
      const url = getURL(agm.id, preSelectedTrigger.id);
      history.push(url);
    }

    const thisTrigger = { ...triggers?.find(
      ({ id }) => id === trigger?.id
    )};

    if (thisTrigger?.id) {
      const url = getURL(agm?.id, thisTrigger.id);
      history.push(url);
    }
  }, [JSON.stringify(agm?.trigger_set)]);

  /**
   *
   * @param trigger
   */
  const handleTriggerClick = (t: TriggerType) => {
    if (!agm) return;
    const url = getURL(agm.id, t.id);
    history.push(url);
  };

  if (!auth) return null;

  const Header = () => (
    <QubeTriggersOverlayPresetContainer>
      <div>
        <AGMTitle>{`${agm?.name} - Qube trigger`}</AGMTitle>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typograph
          type="caption"
          text={`${triggers?.length || 0} triggers total`}
        />
      </div>
    </QubeTriggersOverlayPresetContainer>
  );

  if (!open || !agm) {
    return null;
  }

  return (
    <Overlay
      id="GT_DRAGGABLE_QUBE_TRIGGERS_OVEERLAY_WRAPPER"
      onClose={handleClose}
      Header={Header}
      x={inspectionContext.state.selected_point ? -372 : -10}
      y={100}
    >
      <QubeTriggersOverlayContentWrapper>
        <QubeTriggersOverlaySidebar style={{ maxHeight: `${window.innerHeight - 170}px` }}>
          <SatellitePassages
            agm={agm}
            onTriggerClick={handleTriggerClick}
            authToken={auth.token}
            isObserver={inspectionContext.state.permission_type === 'observer'}
            selected={trigger}
          />
        </QubeTriggersOverlaySidebar>

        <QubeTriggersOverlayContent>
          <Typograph
            type="headline6"
            text={formatTimezoneDate({
              date: getTriggerTstamp(trigger),
              timezone: settingsContext.state.timezone?.id || 'UTC',
              format: 'H:mm:ss A',
            })}
          />

          <Charts trigger={trigger} run_id={inspectionContext.run!.id!} />
        </QubeTriggersOverlayContent>
      </QubeTriggersOverlayContentWrapper>
    </Overlay>
  );
};

export default QubeTriggersOverlay;
