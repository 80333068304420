// external imports
import { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// context
import AccountStore from 'app/modules/account/account.context';
// interfaces
import { ProjectType, RunType } from 'app/interfaces/inspection.interfaces';
import { AuthType } from 'app/modules/account/account.context.d';
// components
import NavigationContainer from 'app/components/navigation-container/navigation-container';
import Breadcrumbs, { Breadcrumb } from 'app/components/breadcrumbs/breadcrumbs';
import CreateProjectModal from 'app/components/create-project-modal/createProjectModal';
import List from './contents/list/list';
import { handleLoadMore, init } from './project-list.controller';
// stylesheet
import { ProjectContainer, Content } from './project-list.page.style';

/**
 *
 * @returns
 */
const ProjectListPage = () => {
  // router
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  // contexts
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;

  // state
  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [runs, setRuns] = useState<RunType[]>([]);
  const [isOpenCreateProjectModal, setIsOpenCreateProjectModal] = useState<boolean>(false);
  const [activeRuns, setActiveRuns] = useState<RunType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const breadcrumbs: Breadcrumb[] = [
    {
      id: '#2',
      text: 'Projects Page',
      href: '',
    },
  ];

  useEffect(() => {
    if (!auth?.token) history.push('/');
    else {
      init({
        setLoading,
        setRuns,
        setActiveRuns,
        setProjects,
        id,
        auth,
        page,
        setHasMore,
      });
    }
    if (window._paq)
      window._paq.push(['trackEvent', 'projects', 'view'])
  }, []);

  return (
    <NavigationContainer>
      <ProjectContainer id="project">
        <Breadcrumbs routes={breadcrumbs} />
        <Content>
          <List
            hasMore={hasMore}
            loading={loading}
            projects={projects}
            activeRuns={activeRuns}
            runs={runs}
            onLoadMore={() => {
              handleLoadMore({
                setLoading,
                setRuns,
                setProjects,
                id,
                auth,
                page,
                setHasMore,
                projects,
                runs,
                setPage,
              });
            }}
            onOpenCreateProjectModal={() => { setIsOpenCreateProjectModal(true); }}
          />
        </Content>


        <CreateProjectModal
          opened={isOpenCreateProjectModal}
          onClose={() => { setIsOpenCreateProjectModal(false); }}
        />
      </ProjectContainer>
    </NavigationContainer>
  );
};

export default ProjectListPage;
