// libs
import { ReactElement, useContext, useEffect, useReducer } from "react";
import { Route, useParams, useHistory } from "react-router-dom";
import { useChannelState } from "@rxdjango/react";
// stores
import InspectionStore, { initialInspectionState } from "./inspection.context";
import AccountStore from "../account/account.context";
// pages
import Run from "./run/run.page";
import Report from "./report/report";
import ForbiddenError from "./forbidden-error/forbidden-error";
// types
import { AuthType } from "../account/account.context.d";
import { RunCascadeType } from "./inspection.interfaces.d";
// context
import { InspectionContext, InspectionDispatchType } from "./inspection.context.d";
import { reducer } from "./inspection.reducer";
// channels
import { RunChannel } from "./inspection.channels";
// components
import Toast from "app/components/toast/toast";
import { checkIsAllowedMakeEditions } from "./inspection.controller";
import FloatingRefreshNotification from "app/components/floating-refresh-notification/floating-refresh-notification";

interface Props {
  children: ReactElement;
  mockState?: InspectionContext;
}

/**
 *
 * @param WrappedComponent
 * @param initialState
 * @returns
 */
export const  InspectionModule = ({ children, mockState }: Props) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  
  // Define state and dispatch method for this Context
  const reducerData = useReducer(reducer, { ...initialInspectionState as InspectionContext, ...mockState });
  const state: InspectionContext = reducerData[0];
  const dispatch: (data: InspectionDispatchType) => void = reducerData[1];
  
  // Project Channel
  const channel = new RunChannel(parseInt(id), auth.token);
  const { state: runState, no_connection_since } = useChannelState<RunCascadeType>(channel);
  const run = runState as RunCascadeType;
  
  useEffect(() => {
    if (run?.project?.id) {
      checkIsAllowedMakeEditions(
        run.project.id,
        auth.token,
        (permissionType) => {
          dispatch({ type: 'SET_PERMISSION_TYPE', data: permissionType });
        },
      );
    }
  }, [run?.project?.id]);

  useEffect(() => {
    if (no_connection_since && !run) {
      history.push(`/runs/${id}/forbidden`);
    }
    
    dispatch({ type: 'SET_NO_CONNECTION_SINCE', data: no_connection_since });
  }, [no_connection_since]);

  return (
    <InspectionStore.Provider value={{ state, dispatch, run }}>
      <Route exact path="/runs/:id" component={Run} />
      <Route exact path="/runs/:id/forbidden" component={ForbiddenError} />
      <Route exact path="/runs/:id/report" component={Report} />
      {children}
      <Toast />
      <FloatingRefreshNotification noConnectionSince={no_connection_since} />
    </InspectionStore.Provider>
  );
};

export default InspectionModule
