import { ReactElement, useContext, useReducer } from 'react';
import { Route, Switch } from 'react-router-dom';
import Calendar from './calendar/calendar.page';
import Project from './project/project.page';
import People from './people/people.page';
import ProjectList from './project-list/project-list.page';
import Toast from 'app/components/toast/toast';
import { reducer } from './projects.reducer';
import { ProjectsContext, ProjectsDispatchType } from './projects.context.d';
import ProjectsStore from './projects.context';
import { initialProjectsState } from './project.state';
import { ActiveRunSummaryChannel } from '../inspection/inspection.channels';
import AccountStore from '../account/account.context';
import { ActiveRunSummaryType } from '../inspection/inspection.interfaces';
import { useChannelState } from '@rxdjango/react/dist/hook';

interface Props {
  value?: unknown;
  testing?: boolean;
  children: ReactElement;
}

/**
 * 
 * @returns 
 */
const ProjectsModule = ({ children, value }: Props) => {
  const reducerData = useReducer(reducer, { ...initialProjectsState, ...value as ProjectsContext });
  const accountContext = useContext(AccountStore);

  const state: ProjectsContext = reducerData[0];
  const dispatch: (data: ProjectsDispatchType) => void = reducerData[1];
  // Project Channel
  const channel = new ActiveRunSummaryChannel(accountContext.state.auth!.token);
  const { state: activeRuns, no_connection_since } = useChannelState(channel);

  const providerValue = {
    state,
    dispatch,
    active_runs: (activeRuns || []) as ActiveRunSummaryType[],
    no_connection_since
  };
  
  return (
    <ProjectsStore.Provider value={providerValue}>
      <Switch>
        <Route exact path="/calendar" component={Calendar} />
        <Route exact path="/people" component={People} />
        <Route exact path="/projects" component={ProjectList} />
        <Route path="/projects/:id" component={Project} />
        {children}

        <Toast />
      </Switch>
    </ProjectsStore.Provider>

  );
}

export default ProjectsModule;
