import { SettingsContext } from "app/modules/settings/settings.context.d";
import { formatTimezoneDate } from "@/lib/formatter";
import { convertXLXSPoint } from "./exporters.formatter";
import { InspectionContext } from "../../inspection.context.d";
import { utils, write } from "xlsx";
import { saveAs } from "file-saver";
import { RunCascadeType } from "../../inspection.interfaces";
import { generateColumns } from "../devices/desktop/components/tracking-sheet/tracking-sheet.controller";
import { AuthType } from "app/modules/account/account.context.d";

const generateXlxs = (pointList: any, settingsState: SettingsContext, runName: string) => {
  const assingAt = formatTimezoneDate({
    date: new Date(),
    timezone: settingsState.timezone?.id || 'UTC',
    format: 'YYYY-MM-DD_-_HH:mm:ss'
  }).replace('-', 'Y').replace('-','M').replace('_', 'D_').replace(':', 'H').replace(':', 'M') + 'S';
  
  const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  // Desired file extesion
  const fileExtension = ".xlsx";

  //Create a new Work Sheet using the data stored in an Array of Arrays.
  const workSheet = utils.json_to_sheet(pointList as unknown as string[][]);
  // Generate a Work Book containing the above sheet.
  const workBook = {
    Props: {
      Application: 'Global Track - CDI',
    },
    Sheets: { data: workSheet, cols: [] },
    SheetNames: ["data"],
  };
  const timezoneStr = settingsState.timezone?.label?.replace(/ /g, '_');

  // Exporting the file with the desired name and extension.
  const excelBuffer = write(workBook, { bookType: "xlsx", type: "array" });
  const fileData = new Blob([excelBuffer], { type: fileType });
  const filenameStr = `${runName}_-_${assingAt}_-_${timezoneStr}`;

  saveAs(fileData, filenameStr + fileExtension);
}


/**
 * @param 
 * @returns 
 */
export const exportxlxs = (
  inspectionState: InspectionContext,
  settingsState: SettingsContext,
  run: RunCascadeType,
) => {
  const columns = inspectionState.grid?.getColumns().filter((col) => {
    const c = col as any;
    if (c.field === 'ete') return false;
    else if (c.field === 'input_type') return false;
    else if (c.field === 'triggers') return false;
    else {
      return true;
    }
  });
  const columnFields = columns?.map((column: any) => column.field) || [];
  const columnNames = columns?.map((column: any) => column.name) || [];
  const pointList = run.trackingpoint_set?.map((point) => {
    return convertXLXSPoint(point, columnFields, columnNames, settingsState);
  }) as string[][];
  generateXlxs(pointList, settingsState, run?.name || '')
}

/**
 * @param 
 * @returns 
 */
export const exportXlxsFromCascadeRun = (
  run: RunCascadeType,
  settingsState: SettingsContext,
  auth: AuthType,
) => {
  const columns = generateColumns(
    { dispatch: () => {}, run: undefined, state: {} as InspectionContext },
    settingsState,
    undefined,
    false, // is observer
    auth,
    () => null, // setTriggerPoint
    () => null, // setBounds
  );
  const columnFields = columns.map((column: any) => column.field);
  const columnNames = columns.map((column: any) => column.name);
  const pointList = run?.trackingpoint_set?.map((p) => {
    return convertXLXSPoint(p, columnFields, columnNames, settingsState)
  }) as string[][];
  
  generateXlxs(pointList, settingsState, run.name || '');
}
