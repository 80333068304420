import styled from 'styled-components';

export const GeophonePlayerControls = styled.div`
  position: absolute;
  top: 36px;
  right: -10px;

  display: grid;
  justify-items: center;
  align-items: center;
  gap: 4px;

  opacity: 0.8;

  .toltip-message {
    bottom: calc(100% + 10px);
    left: -10px;

    font-size: 10px;
    padding: 4px;

    &:before {
      left: 13px;
    }
  }

  > div > button {
    padding: 0;
    width: 16px;
    height: 16px;

    font-size: 16px;

    color: #6d7186;
  }

  > div#gainControl {
    margin-top: 4px;

    display: grid;
    grid-template-columns: auto 1fr;
    justify-items: center;
    align-items: center;
    gap: 4px;

    width: 60px;
    transform: rotate(-90deg) translateX(-28px);

    > div {
      z-index: 1;
      transform: rotate(90deg);

      span {
        font-size: 18px;
        color: #6d7186;
      }
    }

    > input#gainSlider {
      width: 100%;
      appearance: none;
      height: 4px;
      background: #6d7186;
      border: none;
      border-radius: 4px;
      outline: none;
      opacity: 1;

      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 6px;
        background: #6d7186;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 14px;
        height: 14px;
        background: #6d7186;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        top: -4px;
      }

      &::-moz-range-thumb {
        width: 14px;
        height: 14px;
        background: black; /* Ensure the thumb is black */
        border: none;
        border-radius: 50%;
        cursor: pointer;
      }

      &::-ms-thumb {
        width: 14px;
        height: 14px;
        background: #6d7186;
        border: none;
        border-radius: 50%;
        cursor: pointer;
      }

      &::-ms-track {
        width: 100%;
        height: 6px;
        background: transparent;
        border-color: transparent;
        border-width: 6px 0;
        color: transparent;
      }

      &::-webkit-slider-runnable-track {
        background: transparent;
        border: none;
      }

      &:active::-webkit-slider-thumb {
        background: #6d7186;
      }
    }
  }
`;

export const NoDataWrapper = styled.div<{ height?: number, width?: number }>`
  width: 100%;
  max-height: 300px;
  display: flex;
  
  justify-content: center;
  align-items: center;

  color: #6d7186;
  
  ${({ height }) => height && `height: ${height}px;`}
`;
